import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { SessionReducer } from "../slices/session";
import { UserReducer } from "../slices/user";
import { mainPoolReducer } from "../slices/mainPool";
import { ResultsReducer } from "../slices/results";
import { UsersStatusReducer } from "../slices/usersStatus";
import { AppControlReducer } from "../slices/appControl";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "session"],
};

const rootReducer = combineReducers({
  session: SessionReducer,
  user: UserReducer,
  mainPool: mainPoolReducer,
  results: ResultsReducer,
  usersStatus: UsersStatusReducer,
  appControl: AppControlReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
