import React, { useMemo } from "react";

import { Button } from "./button";

import { useSpiderman } from "../../hooks/useSpiderman";

import closeImg from "../../assets/close.webp";
import doddles from "../../assets/doddles.webp";

export const OwnerDetail = ({ onClose, owner }) => {
  const { createUserCode } = useSpiderman();

  const getFormatDate = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const usedCodes = useMemo(() => {
    const count = owner.codes.filter((code) => code.isValid === false);
    return count.length;
  }, [owner.codes]);

  const validCodes = useMemo(() => {
    const count = owner.codes.filter((code) => code.isValid === true);
    return count.length;
  }, [owner.codes]);

  return (
    <div className='flex justify-center  absolute w-[100vw] h-[100vh] bg-[rgba(6,20,37,0.9)] z-50'>
      <div
        className='absolute w-[90vw] h-[70vh] z-0 repeat opacity-[0.03] rounded-2xl mt-16'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[90vw] h-[70vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-16'>
        <div className='flex justify-end items-end w-full'>
          <img
            className='z-30'
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='w-full h-full flex justify-center flex-col items-center '>
          <span className='text-white text-[22px]'>{owner.name}</span>
          <span className='text-sm text-gray-400'>{owner.email}</span>
          <div className='flex flex-col w-full mt-10 h-full'>
            <div className='flex w-full justify-between mt-3'>
              <span className='text-sm'>Fecha de creación: </span>
              <span className='text-sm'>{getFormatDate(owner.createdAt)}</span>
            </div>
            <div className='flex w-full justify-between mt-3'>
              <span className='text-sm'>Suscripción hasta: </span>
              <span className='text-sm'>{getFormatDate(owner.poolExp)}</span>
            </div>
            <div className='flex w-full justify-between mt-10'>
              <span className='text-sm'>User id: </span>
              <span className='text-xs'>{owner.uid}</span>
            </div>
            <div className='flex w-full justify-between mt-3'>
              <span className='text-sm'>Quiniela: </span>
              <span className='text-xs'>{owner.poolId}</span>
            </div>
            <div className='flex w-full justify-between mt-3 mt-10'>
              <span className='text-sm'>Códigos validos: </span>
              <span className='text-xs'>{validCodes}</span>
            </div>
            <div className='flex w-full justify-between mt-3'>
              <span className='text-sm'>Códigos usados: </span>
              <span className='text-xs'>{usedCodes}</span>
            </div>
            <div className='flex grow w-full items-end justify-center z-30'>
              <Button
                label='Crear codigo de invitación'
                onClick={() => {
                  createUserCode(owner.poolId);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
