import "./styles.scss";
import "react-swipe-to-delete-component/dist/swipe-to-delete.css";

import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SwipeToDelete from "react-swipe-to-delete-component";

import {
  AppContainer,
  Button,
  PoolResults,
  CreateMatch,
} from "../../components";
import { useNavigate } from "react-router-dom";

import { usePool } from "../../../hooks/usePool";

import addMatchImg from "../../../assets/add-white.webp";

export const CreatePool = () => {
  const { createMainPool, updateMainPool } = usePool();
  const mainPool = useSelector((state) => state.mainPool);

  const [matches, setMaches] = useState([]);
  const [matchesWithResults, setMachesWithResults] = useState([]);
  const [showCreateMatch, setShowCreateMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    if (mainPool) {
      setMachesWithResults(mainPool.matches);
    }
  }, [mainPool]);

  const runCreateMainPool = async () => {
    setIsLoading(true);
    await createMainPool(matches);
    navigation("/home");
  };

  const createMatch = (localTeam, visitTeam) => {
    const match = {
      localTeam,
      visitTeam,
    };
    matches.push(match);
  };

  const createPool = useCallback(() => {
    return matches.map((match, index) => (
      <SwipeToDelete
        key={`match-${index}`}
        classNameTag='preview-match-swipe w-[100%] mt-3'
        background={null}
        onDelete={() => onDeleteMatch(match.id)}>
        <div className='flex justify-between items-center w-full'>
          <span className='text-xl'>{index + 1}</span>
          <span className='text-xl flex-1 text-center'>{match.localTeam}</span>
          <span className='text-xl'>VS</span>
          <span className='text-xl flex-1 text-center'>{match.visitTeam}</span>
        </div>
      </SwipeToDelete>
    ));
  }, [matches]);

  const poolResultUpdate = (dataResult) => {
    let newMatches = [...matchesWithResults];

    newMatches[dataResult.index] = {
      ...newMatches[dataResult.index],
      result: dataResult.result,
    };

    setMachesWithResults(newMatches);
  };

  const createPoolResults = () =>
    [...mainPool.matches].map((match, index) => (
      <PoolResults
        key={index}
        match={match}
        index={index}
        onUpdate={poolResultUpdate}
      />
    ));

  const updatePool = async () => {
    setIsLoading(true);
    await updateMainPool(matchesWithResults);
    navigation("/home");
  };

  const onDeleteMatch = (matchId) => {
    setMaches(matches.filter((matches) => matches.id !== matchId));
  };

  return (
    <>
      {showCreateMatch && (
        <CreateMatch
          onClose={() => setShowCreateMatch(false)}
          onAddMatch={createMatch}
        />
      )}
      <AppContainer verifyAuth={true} isLoading={isLoading}>
        <div className='flex flex-col items-center w-[100%] justify-center z-20 h-full'>
          <span>{!mainPool ? "Crear quiniela" : "Actualizar resultados"}</span>
          <div className='flex flex-col w-[90%] flex-1 w-full my-10 items-center'>
            {!mainPool && (
              <div className='h-[55vh] overflow-scroll	w-[100%]'>
                {createPool()}
              </div>
            )}

            {matches.length > 5 && (
              <div className='flex justify-center w-full mt-10'>
                <Button label='Crear' onClick={runCreateMainPool} />
              </div>
            )}

            {mainPool && (
              <div className='h-[60vh] w-full  overflow-auto'>
                {createPoolResults()}
              </div>
            )}
            {mainPool && (
              <div className='flex flex-grow mt-10 justify-center items-center'>
                {
                  <Button
                    label='Actualizar'
                    onClick={updatePool}
                    disabled={false}
                  />
                }
              </div>
            )}
          </div>
          {!mainPool && (
            <div className='flex justify-end w-full pr-5'>
              <img
                src={addMatchImg}
                alt='Add match'
                width={44}
                height={44}
                onClick={() => setShowCreateMatch(true)}
              />
            </div>
          )}
        </div>
      </AppContainer>
    </>
  );
};
