import { useApi } from "./useApi";

export const useSpiderman = () => {
  const { client } = useApi();

  const createOwnerCode = async () => {
    try {
      const codes = await client.post("/v1/spiderman/codes/owners");
      return codes;
    } catch (err) {}
  };

  const getOwnerCodes = async () => {
    try {
      const codes = await client.get("/v1/spiderman/codes/owners");
      return codes;
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const getOwners = async () => {
    try {
      return await client.get("/v1/spiderman/users/owners");
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const createUserCode = async (poolId) => {
    try {
      return await client.post("/v1/spiderman/codes/owners/users", {
        poolId,
      });
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  return { getOwnerCodes, createOwnerCode, getOwners, createUserCode };
};
