import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    uid: "",
    name: "",
    poolId: "",
    email: "",
    pool: null,
  },
  reducers: {
    setUser: (state, action) => {
      return { ...action.payload };
    },
    clearUser: () => {
      return { uid: "", name: "", poolId: "", email: "", pool: null };
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const UserReducer = userSlice.reducer;
