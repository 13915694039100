import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { AppContainer, OwnerDetail, UserDetail } from "../../components";

import clsx from "clsx";

import { useUsers } from "../../../hooks/useUsers";
import { useSpiderman } from "../../../hooks/useSpiderman";

import enableImg from "../../../assets/enable.webp";
import disableImg from "../../../assets/disable.webp";
import adminImg from "../../../assets/admin.webp";

import { env } from "../../../env";

export const AdminUsers = () => {
  const users = useSelector((state) => state.usersStatus);
  const user = useSelector((state) => state.user);

  const { getUsersStatus, updateUserStatus } = useUsers();

  const [title, setTitle] = useState("Administración de usuarios");

  const [spidermanCounter, setSpidermanCounter] = useState(0);

  const [showSpidermanView, setShowSpidermanView] = useState(false);

  const [owners, setOwners] = useState([]);

  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showOwnerDetail, setShowOwnerDetail] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);

  const { getOwners } = useSpiderman();

  useEffect(() => {
    getUsersStatus();
  }, []);

  useEffect(() => {
    if (
      spidermanCounter > 4 &&
      !showSpidermanView &&
      user.uid === env.spiderman
    ) {
      setShowSpidermanView(true);
      setTitle("Administración de propietarios");
      setSpidermanCounter(0);
      return;
    }
    if (spidermanCounter > 4 && showSpidermanView) {
      setShowSpidermanView(false);
      setTitle("Administración de usuarios");
      setSpidermanCounter(0);
      return;
    }
  }, [spidermanCounter, showSpidermanView]);

  const fetchOwnersData = useCallback(async () => {
    const owners = await getOwners();
    setOwners(owners);
  }, [getOwners]);

  useEffect(() => {
    if (showSpidermanView) fetchOwnersData();
  }, [showSpidermanView]);

  const updateUser = async (user) => {
    const action = user.active ? "DEACTIVATE" : "ACTIVATE";
    await updateUserStatus(user.uid, action);
    if (showSpidermanView) await fetchOwnersData();
  };

  const showDetail = (selectedUser) => {
    if (showSpidermanView) {
      setSelectedOwner(selectedUser);
      setShowOwnerDetail(true);
      return;
    }
    if (user.owner) {
      setSelectedUser(selectedUser);
      setShowUserDetail(true);
    }
  };

  const getList = (users) => {
    if (users.length > 0) {
      return users.map((user, index) => (
        <div
          key={index}
          className='flex justify-between items-center px-1 border-b-[1px] mb-3'>
          <div className='flex'>
            {!showSpidermanView && user.admin && (
              <img
                src={adminImg}
                alt='Admin'
                width={20}
                height={25}
                className='object-contain mr-2'
              />
            )}
            <span
              onClick={() => showDetail(user)}
              className={clsx("text-white text-lg", {
                "opacity-50": !user.active,
              })}>
              {user.name}
            </span>
          </div>
          <img
            className='w-[18px] h-[18px]'
            src={user.active ? enableImg : disableImg}
            alt='Check'
            onClick={() => updateUser(user)}
          />
        </div>
      ));
    }
  };

  return (
    <>
      {showOwnerDetail && (
        <OwnerDetail
          onClose={() => setShowOwnerDetail(false)}
          owner={selectedOwner}
        />
      )}

      {showUserDetail && (
        <UserDetail
          onClose={() => setShowUserDetail(false)}
          user={selectedUser}
        />
      )}
      <AppContainer>
        <div className='flex h-full flex-col justify-center items-center w-[100%] z-20'>
          <p
            className='text-3xl text-white font-bold text-center w-[50vw]'
            onClick={() => setSpidermanCounter(spidermanCounter + 1)}>
            {title}
          </p>
          <div className='w-[90%] h-[85%] my-10 overflow-auto'>
            {" "}
            {getList(showSpidermanView ? owners : users)}
          </div>
        </div>
      </AppContainer>
    </>
  );
};
