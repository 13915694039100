import { useState } from "react";

export const useManageImage = () => {
  const [base64, setBase64] = useState(null);

  const onImgSelected = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 128;
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const base64String = canvas.toDataURL("image/jpeg");
          setBase64(base64String);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return { onImgSelected, base64 };
};
