import { Events, dispatch } from "../redux/dispatch";

import { useApi } from "./useApi";

export const useResults = () => {
  const { client } = useApi();
  const getResults = async () => {
    try {
      const results = await client.get("/v1/pool/results");
      dispatch(Events.SET_RESULTS, results);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  return { getResults };
};
