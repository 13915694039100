import React from "react";
import { useSelector } from "react-redux";

import userImg from "../../assets/user.webp";
import clsx from "clsx";

export const UserProfile = ({ showEditProfile }) => {
  const user = useSelector((state) => state.user);
  return (
    <div
      className='flex flex-col w-full items-center h-[100px] mt-5 mb-5
    '>
      <div
        className={clsx(
          "rounded bg-[rgba(255,255,255,0.8)] rounded-full w-[100px] h-[100px] items-center flex",
          {
            "p-2": !user.profileImg,
          }
        )}
        onClick={showEditProfile}>
        <img
          src={user.profileImg || userImg}
          alt='User'
          className={clsx("", {
            "w-[100px] h-[100px] object-contain rounded-full": user.profileImg,
          })}
        />
      </div>
      <span className='text-[28px]'>{user.name}</span>
      <span className='text-[14px] text-gray-400'>{user.email}</span>
    </div>
  );
};
