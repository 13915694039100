import { useSelector } from "react-redux";

import { AppContainer } from "../../components";
import { useCodes } from "../../../hooks/useCodes";
import { useSpiderman } from "../../../hooks/useSpiderman";
import { useCallback, useEffect, useState } from "react";

import addImg from "../../../assets/add-white.webp";

import { env } from "../../../env";

export const ManageCodes = () => {
  const { getCodes } = useCodes();

  const { getOwnerCodes, createOwnerCode } = useSpiderman();

  const [codes, setCodes] = useState([]);

  const [ownerCodes, setOwnerCodes] = useState([]);

  const [title, setTitle] = useState("Códigos de invitación");

  const user = useSelector((state) => state.user);

  const [spidermanCounter, setSpidermanCounter] = useState(0);

  const [showSpidermanView, setShowSpidermanView] = useState(false);

  useEffect(() => {
    if (
      spidermanCounter > 4 &&
      !showSpidermanView &&
      user.uid === env.spiderman
    ) {
      setShowSpidermanView(true);
      setTitle("Códigos de invitación para propietarios");
      setSpidermanCounter(0);
      return;
    }
    if (spidermanCounter > 4 && showSpidermanView) {
      setShowSpidermanView(false);
      setTitle("Códigos de invitación");
      setSpidermanCounter(0);
      return;
    }
  }, [spidermanCounter, showSpidermanView]);

  useEffect(() => {
    if (showSpidermanView) fetchOwnerCodes();
  }, [showSpidermanView]);

  const initData = async () => {
    const codes = await getCodes();
    setCodes(codes);
  };

  useState(() => {
    initData();
  }, []);

  const fetchOwnerCodes = async () => {
    const codes = await getOwnerCodes();
    setOwnerCodes(codes);
  };

  const getList = () => {
    if (codes && codes.length)
      return codes.map((code, index) => (
        <span key={`code-${index}`} className='text-base mt-2'>
          {code.code}
        </span>
      ));
  };

  const getSpidermanList = useCallback(() => {
    if (ownerCodes && ownerCodes.length)
      return ownerCodes.map((code, index) => (
        <span key={`code-${index}`} className='text-base mt-2'>
          {code.code}
        </span>
      ));
  }, [ownerCodes]);

  const addOwnerCode = async () => {
    const codes = await createOwnerCode();
    setOwnerCodes(codes);
  };

  return (
    <AppContainer>
      <div className='flex flex-col justify-center items-center h-full w-[100%] z-20'>
        <p
          className='text-3xl text-white font-bold text-center w-[50vw]'
          onClick={() => setSpidermanCounter(spidermanCounter + 1)}>
          {title}
        </p>

        <div className='flex flex-col items-center w-[90%] flex-1 my-10 overflow-auto mt-[5rem]'>
          {showSpidermanView ? getSpidermanList() : getList()}
        </div>
        {showSpidermanView && (
          <div className='flex w-full justify-end mr-[15vw] mb-10'>
            <img
              src={addImg}
              alt='Add'
              width={48}
              height={48}
              onClick={addOwnerCode}
            />
          </div>
        )}
      </div>
    </AppContainer>
  );
};
