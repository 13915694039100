import "./styles.scss";

import { useSelector } from "react-redux";

import { AppContainer, ResultDetail } from "../../components";

import { useResults } from "../../../hooks/useResults";
import { useState } from "react";

import clsx from "clsx";

export const Results = () => {
  const { getResults } = useResults();

  const results = useSelector((state) => state.results);

  const [showDetail, setShowDetail] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useState(() => {
    getResults();
  }, []);

  const getList = () => {
    if (results && results.length > 0) {
      const usersObject = results.map((user) => ({
        name: user.name,
        total: user.total,
        matches: user.matches,
        status: user.status,
      }));

      const sorterArray = usersObject.sort((a, b) => b.total - a.total);

      return sorterArray.map((user, index) => (
        <div
          key={index}
          className='user-result-container'
          onClick={() => {
            if (user.status === "PLAYING") {
              setSelectedUser(user);
              setShowDetail(true);
            }
          }}>
          <span
            className={clsx("name", {
              "opacity-50":
                user.status === "GAME_OVER" || user.status === "WAITING_START",
            })}>
            {user.name}:
          </span>

          <span
            className={clsx("result", {
              "opacity-50":
                user.status === "GAME_OVER" || user.status === "WAITING_START",
            })}>
            {user.total}
          </span>
        </div>
      ));
    }
  };

  return (
    <>
      {showDetail && (
        <ResultDetail
          data={selectedUser}
          onClose={() => {
            setSelectedUser(null);
            setShowDetail(false);
          }}
        />
      )}
      <AppContainer>
        <div className='flex flex-col items-center w-[100%] z-10'>
          <p className='text-center text-3xl text-white font-bold'>
            Resultados
          </p>
          {results && Boolean(results.length) && (
            <div className='border-t-[1px] h-[85%] max-h-[80vh] overflow-auto w-[90%] mt-10'>
              {getList()}
            </div>
          )}
        </div>
      </AppContainer>
    </>
  );
};
