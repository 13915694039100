import { createSlice } from "@reduxjs/toolkit";

const usersStatusSlice = createSlice({
  name: "usersStatus",
  initialState: [],
  reducers: {
    setUsersStatus: (_, action) => [...action.payload],
  },
});

export const { setUsersStatus } = usersStatusSlice.actions;

export const UsersStatusReducer = usersStatusSlice.reducer;
