import "./App.scss";
import { AppRoutes } from "./routes";
import { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../src/configurations";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  const [finishSession, setFinishSession] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (_) => setFinishSession(true));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {finishSession ? (
          <div className='app'>
            <AppRoutes />
          </div>
        ) : (
          <></>
        )}
      </PersistGate>
    </Provider>
  );
}

export default App;
