import { store } from "../store";

import { Events } from "../events";

import { setUser, clearUser } from "../slices/user";

import { setMainPool } from "../slices/mainPool";

import { setResults } from "../slices/results";

import { setUsersStatus } from "../slices/usersStatus";

import { setSession } from "../slices/session";

import { setIsLoading } from "../slices/appControl";

const dispatch = (event, payload) => {
  switch (event) {
    case Events.SET_USER:
      store.dispatch(setUser(payload));
      break;

    case Events.SET_MAIN_POOL:
      store.dispatch(setMainPool(payload));
      break;

    case Events.SET_RESULTS:
      store.dispatch(setResults(payload));
      break;

    case Events.SET_USERS_STATUS:
      store.dispatch(setUsersStatus(payload));
      break;

    case Events.SET_SESSION:
      store.dispatch(setSession(payload));
      if (!payload.token) store.dispatch(clearUser());
      break;

    case Events.SET_IS_LOADING:
      store.dispatch(setIsLoading(payload));
      break;

    default:
      break;
  }
};

export { Events, dispatch };
