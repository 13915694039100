import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: null,
    expiration: null,
  },
  reducers: {
    setSession: (state, action) => {
      state.token = action.payload.token;
      state.expiration = action.payload.expiration;
    },
  },
});

export const { setSession } = sessionSlice.actions;

export const SessionReducer = sessionSlice.reducer;
