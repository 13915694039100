import React from "react";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import doddles from "../../assets/doddles.webp";
import { useSelector } from "react-redux";

const Progress = styled(CircularProgress)(() => ({
  ".MuiCircularProgress-svg": {
    color: "white",
  },
}));

export const AppContainer = ({
  children,
  verifyAuth = false,
  isLogin = false,
}) => {
  const session = useSelector((state: any) => state.session);
  const { isLoading } = useSelector((state: any) => state.appControl);

  const navigate = useNavigate();

  useEffect(() => {
    if (verifyAuth) {
      if (!session.token) {
        navigate("/login");
        return;
      }
    }
    if (isLogin && session.token) {
      navigate("/home");
    }
  }, [session]);

  return (
    <div className='flex justify-center items-center w-full h-[100vh]  max-w-[512px] bg-[rgb(23,43,69)]'>
      {isLoading && (
        <div className='absolute bg-black w-full h-full flex justify-center items-center z-[60] opacity-40'>
          <Progress color='secondary' />
        </div>
      )}
      <div className='h-[100vh] flex justify-center items-center h-full w-full z-40'>
        <div className='w-full h-[95%]'>{children}</div>
      </div>

      <div
        className='absolute w-full h-[100vh] max-w-[512px] z-0 repeat opacity-[0.09]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
    </div>
  );
};
