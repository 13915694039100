import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& input": {
    color: "white",
    height: "2.3vh",
  },
  width: "70vw",
  maxWidth: "420px",
  marginTop: "22px",

  "& label.MuiFormLabel-colorPrimary": {
    color: "#b8b8b8",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.MuiFormLabel-filled": {
    color: "#b8b8b8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#b8b8b8",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },

    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export const Input = ({ value, password = false, ...props }) => {
  return (
    <CssTextField
      value={value}
      type={password ? "password" : "text"}
      {...props}
    />
  );
};
