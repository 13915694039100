import React, { useState } from "react";

import { Button } from "./button";
import { Input } from "./input";

import closeImg from "../../assets/close.webp";

import doddles from "../../assets/doddles.webp";

export const CreateMatch = ({ onClose, onAddMatch }) => {
  const [localTeam, setLocalTeam] = useState("");
  const [visitTeam, setVisitTeam] = useState("");

  return (
    <div className='flex justify-center absolute w-[100vw] h-[100vh] bg-gray-400 z-50 bg-[rgba(6,20,37,0.9)]'>
      <div
        className='absolute w-[90vw] h-[65vh] z-10 repeat opacity-[0.03] rounded-2xl mt-[10.5vh]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col items-center w-[90vw] h-[65vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-20'>
        <div className='w-full flex justify-end z-30'>
          <img
            src={closeImg}
            className='z-30'
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='flex w-full justify-center mt-3'>
          <span className='text-[28px]'>Agregar partido</span>
        </div>
        <div className='flex flex-1 flex-col items-center mt-10 z-30'>
          <Input
            value={localTeam}
            onChange={(event: any) => setLocalTeam(event.target.value)}
            label='Local'
            autoComplete='off'
          />
          <span className='mt-5 text-2xl'>VS</span>
          <Input
            value={visitTeam}
            onChange={(event: any) => setVisitTeam(event.target.value)}
            label='Visitante'
            autoComplete='off'
          />
        </div>
        <div className='z-30 mb-10'>
          <Button
            label={"Agregar"}
            onClick={() => {
              onAddMatch(localTeam, visitTeam);
              onClose();
            }}
            disabled={!(localTeam.length > 2 && visitTeam.length > 2)}
          />
        </div>
      </div>
    </div>
  );
};
