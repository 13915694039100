import { createSlice } from "@reduxjs/toolkit";

const appControlSlice = createSlice({
  name: "appControl",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = appControlSlice.actions;

export const AppControlReducer = appControlSlice.reducer;
