import { createSlice } from "@reduxjs/toolkit";

const resultsSlice = createSlice({
  name: "user",
  initialState: [],
  reducers: {
    setResults: (state, action) => [...action.payload],
  },
});

export const { setResults } = resultsSlice.actions;

export const ResultsReducer = resultsSlice.reducer;
