import {
  createUserWithEmailAndPassword,
  getIdToken,
  getIdTokenResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import { Events, dispatch } from "../redux/dispatch";

import { auth } from "../configurations";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useAuth = () => {
  const session = useSelector((state) => state.session);

  const login = async (email, password) => {
    try {
      setPersistence(auth, browserLocalPersistence).then(async () => {
        await signInWithEmailAndPassword(auth, email, password);
        await saveSession();
      });
    } catch (err) {
      if (
        err.message.includes("user-not-found") ||
        err.message.includes("wrong-password")
      )
        return window.alert("Verifica tu correo y/o contraseña");

      window.alert("Algo salió mal, intentalo más tarde");
    }
  };

  const signup = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password);
    const user = auth.currentUser;
    return await getIdToken(user);
  };

  const logout = async () => {
    await signOut(auth);
    dispatch(Events.SET_SESSION, {
      token: null,
      expiration: null,
    });
  };

  const saveSession = async () => {
    const user = auth.currentUser;
    const tokenResult = await getIdTokenResult(user);

    dispatch(Events.SET_SESSION, {
      token: tokenResult.token,
      expiration: tokenResult.claims.exp,
    });
  };

  const getAuthToken = async () => {
    if (!session.token) return "";

    if (isInTime()) return session.token;

    let user = auth.currentUser;

    if (!user) return logout();

    const token = await getIdToken(user, true);
    await saveSession();
    return token;
  };

  const recoveryPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      throw Error(err.message);
    }
  };

  const isInTime = () => Math.floor(Date.now() / 1000) < session.expiration;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return { login, signup, logout, getAuthToken, saveSession, recoveryPassword };
};
