import { useApi } from "./useApi";

export const useCodes = () => {
  const { client } = useApi();

  const getCodes = async () => {
    try {
      const codes = await client.get("/v1/codes");
      return codes;
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const validateCode = async (code) => {
    const data = client.get(`/v1/code/${code}`);
    return data;
  };

  return { getCodes, validateCode };
};
