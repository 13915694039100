import clsx from "clsx";
import * as React from "react";

export const Button = ({
  label = "button",
  disabled = false,
  onClick,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "w-[70vw] max-w-[420px] h-[5.2vh] bg-[#eeeeee] rounded text-gray-800 font-semibold",
        {
          "opacity-50": disabled,
        }
      )}
      disabled={disabled}
      {...props}>
      {label}
    </button>
  );
};
