import { useApi } from "./useApi";

import { Events, dispatch } from "../redux/dispatch";

export const usePool = () => {
  const { client } = useApi();

  const getMainPool = async () => {
    const pool = await client.get("/v1/pools");
    dispatch(Events.SET_MAIN_POOL, pool);
  };

  const createMainPool = async (matches) =>
    await client.post("/v1/pools", matches);

  const updateMainPool = async (matches) => {
    await client.patch("/v1/pools", matches);
  };

  const startMainPool = async () => {
    await client.patch("/v1/pools/start");
    await getMainPool();
  };

  const createUserPool = async (matches) => {
    await client.post("/v1/users/pool", matches);
  };

  const resetPool = async () => {
    await client.delete("/v1/pools");
  };

  return {
    createMainPool,
    createUserPool,
    getMainPool,
    resetPool,
    startMainPool,
    updateMainPool,
  };
};
