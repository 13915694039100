export const env = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  xApiKey: process.env.REACT_APP_X_API_KEY,

  //////

  spiderman: process.env.REACT_APP_SPIDERMAN,
};
