import React, { useState, useRef } from "react";
import clsx from "clsx";

import { useUsers } from "../../hooks/useUsers";

import { Button } from "./button";
import closeImg from "../../assets/close.webp";
import doddles from "../../assets/doddles.webp";
import { useManageImage } from "../../hooks/useManageImages";

import { useSelector } from "react-redux";

import userImg from "../../assets/user.webp";

export const EditProfile = ({ onClose }) => {
  const { updateUserProfile } = useUsers();

  const inputRef = useRef(null);
  const user = useSelector((state) => state.user);
  const [name, setName] = useState(user.name);

  const { onImgSelected, base64 } = useManageImage();

  const runUpdateUserProfile = async () => {
    updateUserProfile(base64, name);
    onClose();
  };

  const handleFocus = () => {
    inputRef.current.focus();
  };

  return (
    <div className='flex justify-center  absolute w-[100vw] h-[100vh] bg-[rgba(6,20,37,0.9)] z-50'>
      <div
        className='absolute w-[80vw] h-[55vh] z-0 repeat opacity-[0.03] rounded-2xl mt-16'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[80vw] h-[55vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-16'>
        <input
          type='file'
          id='file-input'
          className='hidden'
          onChange={onImgSelected}
        />
        <div className='flex justify-end items-end w-full'>
          <img
            className='z-30'
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='w-full h-full flex flex-col items-center '>
          <div className='flex flex-col w-full items-center h-[100px] mt-5'>
            <label
              htmlFor='file-input'
              className={clsx(
                "rounded bg-[rgba(255,255,255,0.8)] rounded-full w-[100px] h-[100px] items-center flex"
              )}>
              <img
                src={base64 || user.profileImg || userImg}
                alt='User'
                className={
                  "object-contain w-[100px] h-[100px] rounded-full z-30"
                }
              />
            </label>
            <div className='flex flex-col justify-center items-center mt-3'>
              <input
                ref={inputRef}
                className='flex bg-transparent text-white text-center text-[28px] focus:outline-none z-40 w-[50vw]'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onClick={handleFocus}
              />
              <span className='text-[14px] text-gray-400'>{user.email}</span>
            </div>
          </div>
          <div className='w-full grow flex items-end justify-center mb-5  z-30'>
            <Button
              label='Actualizar información'
              disabled={!base64 && user.name === name}
              onClick={runUpdateUserProfile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
