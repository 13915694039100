import React from "react";

import { useUsers } from "../../hooks/useUsers";

import closeImg from "../../assets/close.webp";
import doddles from "../../assets/doddles.webp";

export const UserDetail = ({ onClose, user }) => {
  const { updateUserAdmin } = useUsers();

  const getFormatDate = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const updateAdmin = async () => {
    const action = user.admin ? "DEACTIVATE" : "ACTIVATE";
    await updateUserAdmin(user.uid, action);
    onClose();
  };

  return (
    <div className='flex justify-center  absolute w-[100vw] h-[100vh] bg-[rgba(6,20,37,0.9)] z-50'>
      <div
        className='absolute w-[80vw] h-[40vh] z-0 repeat opacity-[0.03] rounded-2xl mt-16'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[80vw] h-[40vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-16'>
        <div className='flex justify-end items-end w-full'>
          <img
            className='z-30'
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='w-full h-full flex justify-center flex-col items-center '>
          <span className='text-white text-[22px]'>{user.name}</span>
          <span className='text-sm text-gray-400'>{user.email}</span>
          <div className='flex flex-col w-full mt-10 h-full'>
            <div className='flex w-full justify-between mt-5'>
              <span className='text-sm'>User id: </span>
              <span className='text-xs'>{user.uid}</span>
            </div>
            <div className='flex w-full justify-between mt-5'>
              <span className='text-sm'>Fecha de creación: </span>
              <span className='text-sm'>{getFormatDate(user.createdAt)}</span>
            </div>

            <div className='flex w-full justify-between mt-5'>
              <span className='text-sm'>Administrador: </span>

              <input
                className='z-30'
                type='checkbox'
                defaultChecked={user.admin}
                onClick={updateAdmin}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
