import { Events, dispatch } from "../redux/dispatch";

import { useApi } from "./useApi";

export const useUsers = () => {
  const { client } = useApi();

  const getUser = async () => {
    try {
      const user = await client.get("/v1/users");
      dispatch(Events.SET_USER, user);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const getUsersStatus = async () => {
    try {
      const users = await client.get("/v1/users/status");
      dispatch(Events.SET_USERS_STATUS, users);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const updateUserStatus = async (uid, action) => {
    try {
      await client.patch("/v1/users/status", {
        uid,
        action,
      });

      await getUsersStatus();
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const createUser = async (name, code, token) => {
    try {
      const body = {
        name,
        code,
      };
      await client.post("/v1/users", body, {
        token,
        userStatus: "NEW",
      });
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const updateUserAdmin = async (uid, action) => {
    try {
      await client.patch("/v1/users/admin", {
        uid,
        action,
      });

      await getUsersStatus();
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const updateUserProfile = async (image, name) => {
    try {
      await client.patch("/v1/users/profile", {
        image,
        name,
      });
      dispatch(Events.SET_IS_LOADING, true);
      setTimeout(async () => {
        await getUser();
        return true;
      }, 1000);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  return {
    createUser,
    getUser,
    getUsersStatus,
    updateUserStatus,
    updateUserAdmin,
    updateUserProfile,
  };
};
