import React, { useState, useMemo, useEffect } from "react";

import { Button } from "./button";
import { Input } from "./input";

import { useAuth } from "../../hooks/useAuth";
import { Events, dispatch } from "../../redux/dispatch";

import closeImg from "../../assets/close.webp";

import doddles from "../../assets/doddles.webp";

export const RecoveryPassword = ({ onClose }) => {
  const [email, onChangeEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const { recoveryPassword } = useAuth();

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  useEffect(() => {
    setIsFormValid(emailRegex.test(email));
  }, [emailRegex, email]);

  const run = async () => {
    try {
      dispatch(Events.SET_IS_LOADING, true);
      await recoveryPassword(email.trim());
      onClose();
      window.alert("Te hemos enviado un correo para restablecer tu contraseña");
      dispatch(Events.SET_IS_LOADING, false);
    } catch (err) {
      dispatch(Events.SET_IS_LOADING, false);
      onClose();
      window.alert(
        "Tuvimos un problema al restablecer tu contraseña, verifica tu correo e intenta más tarde."
      );
    }
  };

  return (
    <div className='flex justify-center absolute w-[100vw] h-[100vh] bg-gray-400 z-50 bg-[rgba(6,20,37,0.9)]'>
      <div
        className='absolute w-[90vw] h-[50vh] z-0 repeat opacity-[0.03] rounded-2xl mt-[10vh]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[90vw] h-[50vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-[10vh]'>
        <div className='w-full flex justify-end z-30'>
          <img
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='flex w-full justify-center items-center mt-3'>
          <span className='text-2xl text-center w-full'>
            ¿Cual es tu correo <br /> electrónico?
          </span>
        </div>

        <div className='flex flex-col items-center w-full flex-1 mt-5'>
          <Input
            value={email}
            onChange={(event: any) => onChangeEmail(event.target.value)}
            label='Correo'
            autoComplete='off'
          />
        </div>
        <div className='flex justify-center z-30 mb-10'>
          <Button
            label={"Recuperar contraseña"}
            onClick={run}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </div>
  );
};
