import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Login,
  Home,
  CreatePool,
  ViewPool,
  Results,
  PoolDetail,
  AdminUsers,
  ManageCodes,
} from "../UI/views";
export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/create-pool' element={<CreatePool />} />
        <Route path='/view-pool' element={<ViewPool />} />
        <Route path='/results' element={<Results />} />
        <Route path='/pool-detail' element={<PoolDetail />} />
        <Route path='/admin-users' element={<AdminUsers />} />
        <Route path='/manage-codes' element={<ManageCodes />} />
      </Routes>
    </BrowserRouter>
  );
};
