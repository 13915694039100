import { useEffect, useMemo, useState } from "react";

import { AppContainer, Button, Input } from "../../components";

import { useAuth } from "../../../hooks/useAuth";

import { Signup, RecoveryPassword } from "../../components";

import logo from "../../../assets/logo.webp";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showReocoveryPassword, setShowRecoveryPassword] = useState(false);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  useEffect(() => {
    setIsFormValid(emailRegex.test(email) && password.length >= 6);
  }, [emailRegex, email, password]);

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const { login } = useAuth();

  return (
    <>
      {showSignup && <Signup onClose={() => setShowSignup(false)} />}
      {showReocoveryPassword && (
        <RecoveryPassword onClose={() => setShowRecoveryPassword(false)} />
      )}
      <AppContainer isLogin>
        <div className='flex flex-col h-full'>
          <div className='flex justify-center'>
            <img
              src={logo}
              alt='Logo'
              className='w-[68vw] max-w-[420px] object-contain'
            />
          </div>
          <div className='flex flex-1 flex-col items-center mt-2'>
            <div className='flex flex-1 flex-col items-center mt-10 w-full'>
              <Input
                value={email}
                onChange={onChangeEmail}
                label='Correo'
                autoComplete='off'
              />
              <Input
                value={password}
                onChange={onChangePassword}
                label='Contraseña'
                password
              />

              <div className='w-[70vw] mt-3 flex max-w-[420px] justify-end '>
                <span
                  className='text-[11px] underline'
                  onClick={() => setShowRecoveryPassword(true)}>
                  ¿Olvidaste tu contraseña?
                </span>
              </div>
            </div>

            <div className='flex items-end h-fullbg-blue-400 mb-5'>
              <Button
                label={"Iniciar sesión"}
                onClick={() => login(email.trim(), password.trim())}
                disabled={!isFormValid}
              />
            </div>
            <div className='w-[70vw] mt-3 flex max-w-[420px] mb-10 justify-end'>
              <span className='text-[11px]'>¿Todavia no tienes cuenta?</span>
              <span
                className='text-[11px] ml-1 underline'
                onClick={() => setShowSignup(true)}>
                Crea una aqui
              </span>
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
};
