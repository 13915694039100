import { createSlice } from "@reduxjs/toolkit";

const mainPoolSlice = createSlice({
  name: "mainPool",
  initialState: null,
  reducers: {
    setMainPool: (state, action) => {
      if (!action.payload) return null;
      if (action.payload) return { ...state, ...action.payload };
    },
  },
});

export const { setMainPool } = mainPoolSlice.actions;

export const mainPoolReducer = mainPoolSlice.reducer;
