import axios from "axios";
import { env } from "../env";
import { useAuth } from "./useAuth";

import { useAppControl } from "./useAppControl";

export const useApi = () => {
  const { getAuthToken } = useAuth();
  const { showLoading, hideLoading } = useAppControl();

  const client = axios.create({
    baseURL: `${env.apiBaseUrl}/api`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      showLoading();
      const apiKey = env.xApiKey;

      const token = config.token || (await getAuthToken());

      if (config.userStatus) {
        config.headers["user-status"] = config.userStatus;
      }

      if (apiKey) {
        config.headers["x-api-key"] = apiKey;
      }

      if (token) {
        config.headers["Authorization"] = `${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => {
      const { data, status } = response;

      if (status === 200 || status === 201) {
        const { data: dataResponse } = data;
        hideLoading();
        return dataResponse;
      }

      hideLoading();
      return response;
    },
    (error) => {
      hideLoading();
      if (error.response) {
        const { status, data } = error.response;
        console.error(
          `Error con status ${status}:`,
          data.message || "Error desconocido"
        );
      } else {
        console.error("Error sin respuesta del servidor:", error.message);
      }

      // Rechazar la promesa con el error para que puedas manejarlo en el código que hace la llamada
      return Promise.reject(error);
    }
  );

  return { client };
};
