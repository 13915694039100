import React from "react";

import "./styles.scss";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppContainer,
  AdminPannel,
  UserProfile,
  EditProfile,
} from "../../components";
import pool from "../../../assets/pool.webp";
import results from "../../../assets/results.webp";
import logoutimg from "../../../assets/logout.webp";

import errorImage from "../../../assets/out_of_time.png";
import clsx from "clsx";

import { useUsers } from "../../../hooks/useUsers";
import { usePool } from "../../../hooks/usePool";
import { useSelector } from "react-redux";

import { useAuth } from "../../../hooks/useAuth";

export const Home = () => {
  const navigation = useNavigate();
  const { logout } = useAuth();

  const [showEditProfile, setShowEditProfile] = useState(false);

  const { getMainPool } = usePool();
  const user = useSelector((state: any) => state.user);
  const { getUser } = useUsers();
  const mainPool = useSelector((state: any) => state.mainPool);

  const getData = useCallback(async () => {
    await getUser();
    await getMainPool();
  }, [getUser, getMainPool]);

  useState(() => {
    getData();
  }, []);

  return (
    <>
      {showEditProfile && (
        <EditProfile
          onClose={() => {
            setShowEditProfile(false);
          }}
        />
      )}
      <AppContainer verifyAuth={true}>
        {user && (
          <div className='flex flex-col w-full h-full'>
            <div className='flex flex-col'>
              <UserProfile showEditProfile={() => setShowEditProfile(true)} />
            </div>
            {user.active ? (
              <div className='flex flex-col h-full w-full'>
                <div className='flex flex-1 justify-center'>
                  <div className='gap-[38px] grid grid-cols-3 mt-[5rem]'>
                    <div
                      className={clsx("funtionality", {
                        "opacity-50": !mainPool,
                      })}
                      onClick={() => {
                        if (mainPool) navigation("/view-pool");
                      }}>
                      <div className='img-container'>
                        <img className='img' src={pool} alt='quiniela' />
                      </div>
                      <div className='label-container'>
                        <label>Quiniela</label>
                      </div>
                    </div>
                    <div
                      className={clsx("funtionality", {
                        "opacity-50": !mainPool,
                      })}
                      onClick={() => {
                        if (mainPool) navigation("/results");
                      }}>
                      <div className='img-container'>
                        <img className='img' src={results} alt='quiniela' />
                      </div>
                      <div className='label-container'>
                        <label>Resultados</label>
                      </div>
                    </div>
                    <div className='funtionality' onClick={logout}>
                      <div className='img-container'>
                        <img className='img' src={logoutimg} alt='logout' />
                      </div>
                      <div className='label-container'>
                        <label>Logout</label>
                      </div>
                    </div>
                  </div>
                </div>
                {user.admin && <AdminPannel user={user} />}
              </div>
            ) : (
              <div className='flex flex-col mt-[8rem] items-center h-full w-full'>
                <img
                  className='w-[128px] h-[128px]'
                  src={errorImage}
                  alt='Error'
                />
                <span className='text-center text-white text-xl mt-5'>
                  Cuenta inactiva <br /> Contacta a tu administrador
                </span>
              </div>
            )}
          </div>
        )}
      </AppContainer>
    </>
  );
};
