import React from "react";

import closeImg from "../../../assets/close.webp";
import doddles from "../../../assets/doddles.webp";

import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";

export const ResultDetail = ({ data, onClose }) => {
  const initStyle = {
    color: "gray",
    "&.MuiChecked": {
      color: "gray",
    },
  };

  const winnerStyle = {
    color: "rgb(126,192, 116)",
    "&.MuiChecked": {
      color: "rgb(126,192, 116)",
    },
  };

  const looserStyle = {
    color: "rgba(193,60,52, 200)",
    "&.MuiChecked": {
      color: "rgba(193,60,52, 200)",
    },
  };

  const getStyle = (status, checked) => {
    if (status === "CORRECT" && checked) return winnerStyle;

    if (status === "INCORRECT" && checked) return looserStyle;

    return initStyle;
  };

  const createPoolDetail = () => {
    return data.matches.map((match, index) => {
      return (
        <div
          className='flex w-full mt-1 items-center justify-between'
          key={index}>
          <Checkbox
            disable={true}
            checked={match.result === "L"}
            style={getStyle(match.status, match.result === "L")}
          />
          <span
            className={clsx("text-[14px] text-gray-600 flex-1 text-center", {
              "line-through": match.status === "INCORRECT",
            })}>{`${match.localTeam}`}</span>
          <Checkbox
            disable={true}
            checked={match.result === "T"}
            style={getStyle(match.status, match.result === "T")}
          />
          <span
            className={clsx("text-[14px] text-gray-600 flex-1 text-center", {
              "line-through text-gray-600": match.status === "INCORRECT",
            })}>{`${match.visitTeam}`}</span>
          <Checkbox
            disable={true}
            checked={match.result === "V"}
            style={getStyle(match.status, match.result === "V")}
          />
        </div>
      );
    });
  };

  return (
    <div className='flex justify-center  absolute w-[100vw] h-[100vh] bg-[rgba(6,20,37,0.9)] z-50'>
      <div
        className='absolute w-[90vw] h-[80vh] z-0 repeat opacity-[0.03] rounded-2xl mt-[8.5vh]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[90vw] h-[80vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-16'>
        <div className='flex justify-end items-end w-full'>
          <img
            className='z-30'
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='w-full flex justify-center flex-col items-center '>
          <span className='text-white text-[22px]'>{data.name}</span>
          <span className='text-xs mt-2 text-[#bbbbbb]'>{`Aciertos: ${data.total}`}</span>
        </div>
        <div className='mt-5 grow w-full overflow-scroll'>
          {createPoolDetail()}
        </div>
      </div>
    </div>
  );
};
