import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";

// hooks

import { usePool } from "../../hooks/usePool";

import { getFormatDate } from "../../utils";

// images
import createPool from "../../assets/add.webp";
import playingImg from "../../assets/playing.webp";
import initPoolImg from "../../assets/init-pool.webp";
import usersImg from "../../assets/admin-users.webp";
import resetimg from "../../assets/reload.webp";
import uploadImg from "../../assets/update.webp";
import manageCodesImg from "../../assets/manage-codes.webp";

export const AdminPannel = ({ user }) => {
  const navigation = useNavigate();

  const mainPool = useSelector((state: any) => state.mainPool);
  const [counterToReset, setCounterToReset] = useState(0);

  const { startMainPool, resetPool } = usePool();

  const initPool = async () => {
    if (!mainPool.start) await startMainPool();
  };

  const reset = async () => {
    if (counterToReset > 3) {
      await resetPool();
      window.location.reload();
      return;
    }
    setCounterToReset(counterToReset + 1);
  };

  return (
    <>
      <span className='text-3xl text-white text-center mt-10'>
        Administración
      </span>
      {user.owner && (
        <span className='text-xs text-gray-400 text-center mt-1'>
          {`Suscripción valida hasta: ${getFormatDate(user.poolExp)}`}
        </span>
      )}

      <div className='flex justify-center mb-[5rem] mt-5'>
        <div className='gap-[38px] grid grid-cols-3 mt-5'>
          <div
            className='funtionality'
            onClick={() => navigation("/create-pool")}>
            <div className='img-container'>
              <img
                className='img'
                src={mainPool ? uploadImg : createPool}
                alt='quiniela'
              />
            </div>
            <div className='label-container'>
              <label>{mainPool ? "Actualizar" : "Crear"}</label>
            </div>
          </div>
          <div
            className='funtionality'
            onClick={() => navigation("/admin-users")}>
            <div className='img-container'>
              <img className='img' src={usersImg} alt='admin users' />
            </div>
            <div className='label-container'>
              <label>Usuarios</label>
            </div>
          </div>
          <div
            className='funtionality'
            onClick={() => navigation("/manage-codes")}>
            <div className='img-container'>
              <img className='img' src={manageCodesImg} alt='admin codes' />
            </div>
            <div className='label-container'>
              <label>Codigos</label>
            </div>
          </div>

          {user.owner && mainPool && (
            <div
              className={clsx("funtionality", {
                visible: user.owner,
                invisible: !user.owner,
              })}
              onClick={initPool}>
              <div className='img-container'>
                <img
                  className='img'
                  src={mainPool && mainPool.start ? playingImg : initPoolImg}
                  alt='quiniela'
                />
              </div>
              <div className='label-container'>
                <label>
                  {mainPool && mainPool.start ? "Jugando" : "Iniciar"}
                </label>
              </div>
            </div>
          )}

          {user.owner && mainPool && mainPool.start && (
            <div
              className={clsx("funtionality", {
                visible: user.owner,
                invisible: !user.owner,
              })}
              onClick={reset}>
              <div className='img-container'>
                <img className='img' src={resetimg} alt='quiniela' />
              </div>
              <div className='label-container'>
                <label>Reiniciar</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
