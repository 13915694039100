import "./styles.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { AppContainer, Button, PoolResults } from "../../components";
import outOfTimeImg from "../../../assets/out_of_time.png";

import { usePool } from "../../../hooks/usePool";
import { useUsers } from "../../../hooks/useUsers";

export const ViewPool = () => {
  const mainPool = useSelector((state) => state.mainPool);
  const user = useSelector((state) => state.user);

  const [matchesWithResults, setMachesWithResults] = useState([]);
  const [isPoolValid, setIsPoolValid] = useState(false);

  const { createUserPool } = usePool();
  const { getUser } = useUsers();

  useEffect(() => {
    if (mainPool) setMachesWithResults(mainPool.matches);
  }, [mainPool]);

  const poolResultUpdate = (dataResult) => {
    let newMatches = [...matchesWithResults];

    newMatches[dataResult.index] = {
      ...newMatches[dataResult.index],
      result: dataResult.result,
    };

    validPool(newMatches);
    setMachesWithResults(newMatches);
  };

  const validPool = (matches) => {
    let valid = true;
    matches.forEach((match) => {
      if (!match.result) valid = false;
    });
    setIsPoolValid(valid);
  };

  const createPool = () => {
    if (mainPool.start) {
      return (
        <div className='empty-pool-container'>
          <img className='img' src={outOfTimeImg} alt='out of time' />
          <span className='text-xl'>No llenaste tu quiniela a tiempo</span>
        </div>
      );
    }

    if (mainPool.matches) {
      return mainPool.matches.map((match, index) => (
        <PoolResults
          key={index}
          match={match}
          index={index}
          clear
          onUpdate={poolResultUpdate}
        />
      ));
    }
  };

  const createBlockPool = () => {
    if (user.pool.matches) {
      return user.pool.matches.map((match, index) => (
        <PoolResults key={index} match={match} index={index} disable />
      ));
    }
  };

  const updateUserPool = async () => {
    await createUserPool(matchesWithResults);
    setTimeout(async () => {
      await getUser();
    }, 1000);
  };

  return (
    <AppContainer>
      {Object.keys(mainPool).length === 0 ? (
        <div className='empty-pool-container'>
          <img className='img' src={outOfTimeImg} alt='quiniela' />
          <span className='text-white text-center text-2xl'>
            No tienes quiniela por llenar
          </span>
        </div>
      ) : (
        <div className='flex flex-col w-full h-full items-center z-20'>
          <span className='text-white'>Mi quiniela</span>

          <div className='pool-container'>
            {user.pool ? createBlockPool() : createPool()}
          </div>
          {!user.pool && (
            <div className='send-button-container'>
              {isPoolValid && (
                <Button
                  label='Enviar'
                  onClick={updateUserPool}
                  disabled={false}
                />
              )}
            </div>
          )}
        </div>
      )}
    </AppContainer>
  );
};
